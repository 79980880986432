import * as React from 'react';
import {keyboardEvents} from '../../../../constants';
import {DotWithRef} from './Dot/Dot';
import {ThumbnailWithRef} from './Thumbnail/Thumbnail';
import {ForwardRefProps, WithRef} from '../../../../commons/withRef';
import {IProductMediaItem} from '@wix/wixstores-graphql-schema/dist/es/src';

export interface IMediaNavigationItemProps extends Partial<ForwardRefProps> {
  index: number;
  handleClick(index: number);
  withDots: boolean;
  isSelected: boolean;
  isVertical: boolean;
  imgUrl: string;
  mediaItem: IProductMediaItem;
}

@WithRef
export class MediaNavigationItem extends React.Component<IMediaNavigationItemProps> {
  constructor(props: IMediaNavigationItemProps) {
    super(props);
  }

  public readonly onClick = () => {
    const {index, handleClick} = this.props;
    handleClick(index);
  };

  public readonly onKeypress = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    const {handleClick, index} = this.props;

    /* istanbul ignore else: todo(ariel): test else */
    if (e.keyCode === keyboardEvents.ENTER.keyCode) {
      handleClick(index);
    } else if (e.keyCode === keyboardEvents.ARROW_RIGHT.keyCode || e.keyCode === keyboardEvents.ARROW_UP.keyCode) {
      e.preventDefault();
      e.stopPropagation();
      handleClick(index + 1);
    } else if (e.keyCode === keyboardEvents.ARROW_LEFT.keyCode || e.keyCode === keyboardEvents.ARROW_DOWN.keyCode) {
      e.preventDefault();
      e.stopPropagation();
      handleClick(index - 1);
    }
  };

  public render() {
    const {forwardedRef, isSelected, index, isVertical, imgUrl, mediaItem} = this.props;

    return this.props.withDots ? (
      <DotWithRef
        isSelected={isSelected}
        ref={forwardedRef}
        index={index}
        handleClick={this.onClick}
        handleKeypress={this.onKeypress}
      />
    ) : (
      <ThumbnailWithRef
        handleClick={this.onClick}
        handleKeypress={this.onKeypress}
        index={index}
        ref={forwardedRef}
        isSelected={isSelected}
        isVertical={isVertical}
        mediaItem={mediaItem}
        imgUrl={imgUrl}
      />
    );
  }
}
