import {defaultProductMediaImage, SocialVendor} from '../../constants';
import {ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import {IProductMediaItem} from '@wix/wixstores-graphql-schema/dist/es/src';
import {IProductDTO} from '../../types/app-types';

export function getDefaultProductMedia(product: IProductDTO): IProductMediaItem {
  return /* istanbul ignore next: todo: test */ product.media && product.media.length > 0
    ? product.media[0]
    : defaultProductMediaImage;
}

export function shouldRenderVendorFromStyledParams(vendor: SocialVendor, props: ProvidedGlobalProps): boolean {
  const {
    globals: {
      style: {
        styleParams: {booleans},
      },
    },
  } = props;

  const styledKey = `productPage_socialNetwork${vendor}`;

  return booleans[styledKey];
}

export function shouldRenderVendorByWhatsappExperiment(vendor: SocialVendor, experimentValue: boolean): boolean {
  if (experimentValue) {
    return vendor !== SocialVendor.Google && vendor !== SocialVendor.Fancy;
  }

  return vendor !== SocialVendor.WhatsApp;
}
