import React from 'react';
import cx from 'classnames';
import s from './QuickViewLayout.scss';
import {Cell} from '../Cell/Cell';
import {LayoutComponentProps} from '../../../types/app-types';
import {ProductName} from '../../ProductName/ProductName';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductPriceContainer} from '../../ProductPriceContainer/ProductPriceContainer';
import {ProductSku} from '../../ProductSku/ProductSku';
import {ResponsiveGallery} from '../ResponsiveLayout/ResponsiveGallery/ResponsiveGallery';
import {ViewMore} from '../../ViewMore/ViewMore';

export const QuickViewLayout: React.FunctionComponent<LayoutComponentProps> = ({product, settings}) => (
  <main className={s.content}>
    <section className={cx(s.col, s.left)}>
      <Cell className={s.gallery}>
        <ResponsiveGallery media={product.media} imageMode={settings.imageMode} imageRatio={settings.imageRatioId} />
      </Cell>
    </section>
    <section className={cx(s.col, s.right)}>
      <Cell>
        <ProductName name={product.name} />
      </Cell>

      <Cell className={s.price}>
        <ProductPriceContainer />
      </Cell>

      <Cell className={s.sku}>
        <ProductSku />
      </Cell>

      <Cell className={s.options}>
        <ProductOptions shouldShowQuantity={settings.shouldShowQuantity} />
      </Cell>

      <Cell className={s.actions}>
        <ProductPageButtonsContainer />
      </Cell>

      <Cell className={s.viewMore}>
        <ViewMore />
      </Cell>
    </section>
  </main>
);
