import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'lodash';
import cx from 'classnames';
import s from './ModalGallery.scss';
import {IMedia} from '@wix/wixstores-client-core/dist/src/types/product';
import {getMediaUrl} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {ArrowLeft, ArrowRight} from '../../icons/dist';
import {CloseWithBackground} from '../../icons/dist/components/CloseWithBackground';
import {timeout, preventDefault} from './lib/utils';
import {VIDEO_PROPS, IMAGE_PROPS, VERTICAL_MARGINS} from './lib/constants';

export interface ModalGalleryProps {
  currentIndex: number;
  handleClose(): void;
  handleNavigateTo(index: number): void;
  handleMount?(): void;
  isMobile: boolean;
  media: IMedia[];
  dpr: 1 | 2;
}

export interface ModalGalleryState {
  width: number;
  height: number;
  zoom: Zoom;
  zoomOffset: ZoomAxis;
}

export enum Hooks {
  ArrowNext = 'modal-gallery-arrow-next',
  ArrowPrev = 'modal-gallery-arrow-prev',
  DotNavigation = 'modal-gallery-dots',
  Close = 'modal-gallery-close',
  Container = 'modal-gallery-container',
  Header = 'modal-gallery-header',
  MediaNode = 'modal-gallery-media-node',
  Root = 'modal-gallery-root',
}

const enum ZoomFactor {
  ACTIVE = 2,
  INACTIVE = 1,
}

const enum PinchType {
  IN = 'IN',
  OUT = 'OUT',
}

const enum Zoom {
  ON = 'ON',
  OFF = 'OFF',
}

interface ZoomAxis {
  x: number;
  y: number;
}

export class ModalGallery extends React.Component<ModalGalleryProps, ModalGalleryState> {
  private currentAxis: ZoomAxis = {x: 0, y: 0};
  private readonly mediaOptions = {isSEOBot: true};
  private taps;
  private touchHypot: number[] = [0];
  public media: React.RefObject<any> = React.createRef();
  public root = React.createRef<HTMLDivElement>();
  public warmed: string[] = [];

  public state = {
    width: undefined,
    height: undefined,
    zoom: Zoom.OFF,
    zoomOffset: {x: 0, y: 0},
  };

  public static defaultProps = {
    currentIndex: 0,
    dpr: 1,
    handleClose: _.noop,
    handleNavigateTo: _.noop,
    handleMount: _.noop,
    isMobile: false,
    media: [],
  };

  private async setDimensions(): Promise<void> {
    return new Promise(resolve => {
      this.setState(
        {
          width: this.root.current.offsetWidth || 500,
          height: this.root.current.offsetHeight || 500,
        },
        resolve
      );
    });
  }

  private warmUpAllMedia(): void {
    this.props.media.forEach(m => this.warmUp(m, ZoomFactor.INACTIVE));
  }

  public async componentDidMount() {
    document.body.classList.add(s.fixed);
    this.root.current.addEventListener('touchstart', preventDefault, {passive: false});
    window.addEventListener('keydown', this.handleKeydown, {passive: false});
    await this.setDimensions();
    this.warmUpAllMedia();
    this.props.handleMount();
  }

  public componentWillUnmount() {
    document.body.classList.remove(s.fixed);
    this.root.current.removeEventListener('touchstart', preventDefault);
    window.removeEventListener('keydown', this.handleKeydown);
  }

  public render() {
    return ReactDOM.createPortal(
      <div>
        {this.renderHeader()}
        <div data-hook={Hooks.Root} className={s.modal} ref={this.root}>
          {this.renderContainer()}
          {!this.isZoomed && this.renderFooter()}
        </div>
      </div>,
      document.body
    );
  }

  private getMediaScales(media: IMedia, zoom: number): {width: number; height: number} {
    const {height: containerHeight, width: containerWidth} = this.state;
    const limitedContainerHeight = containerHeight - VERTICAL_MARGINS;
    const mediaRatio = media.width / media.height;
    const containerRatio = containerWidth / limitedContainerHeight;
    const scale = mediaRatio > containerRatio ? media.width / containerWidth : media.height / limitedContainerHeight;

    return {
      width: Math.ceil((media.width / scale) * zoom),
      height: Math.ceil((media.height / scale) * zoom),
    };
  }

  private get currentMedia() {
    return this.props.media[this.props.currentIndex];
  }

  private renderContainer() {
    const src = this.getMediaSrc(this.currentMedia, this.zoomFactor);
    if (!src) {
      return;
    }
    this.warmUp(this.currentMedia, ZoomFactor.ACTIVE);

    const classNames = cx({
      [s.container]: true,
      [s.zoomOut]: this.isZoomed,
      [s.zoomIn]: !this.isZoomed,
    });

    return (
      <div className={s.box}>
        {!this.isZoomed && this.renderArrows()}
        <div
          key={`modal-gallery-media-${this.currentMedia.url}`}
          className={classNames}
          data-hook={Hooks.Container}
          data-zoomed={this.isZoomed}
          {...this.gestures}>
          {this.renderMedia()}
        </div>
      </div>
    );
  }

  private get gestures() {
    if (this.props.isMobile) {
      return {
        onTouchStart: this.handleOnTouchStart,
        onTouchMove: this.handleOnTouchMove,
        onTouchEnd: e => {
          this.handleOnTouchEnd(e);
          this.handleTap();
        },
      };
    }
    return {
      onMouseMove: this.handleMouseMove,
      onClick: this.handleMouseClick,
    };
  }

  private get zoomFactor() {
    return this.isZoomed ? ZoomFactor.ACTIVE : ZoomFactor.INACTIVE;
  }

  private renderMedia() {
    const {isMobile} = this.props;
    const {zoomOffset} = this.state;
    const mediaScales = this.currentMediaScales();
    const src = this.getMediaSrc(this.currentMedia, this.zoomFactor);
    const direction = isMobile ? 1 : -1;
    const nextX = -mediaScales.width / 2 - zoomOffset.x * direction;
    const nextY = -mediaScales.height / 2 - zoomOffset.y * direction;
    const transform = `translate3d(${nextX}px, ${nextY}px, 0)`;
    const isVideo = this.currentMedia.mediaType === 'VIDEO';
    return React.createElement(isVideo ? 'video' : 'img', {
      'data-hook': Hooks.MediaNode,
      'data-axis': `${zoomOffset.x}-${zoomOffset.y}`,
      src,
      className: s.media,
      draggable: false,
      height: mediaScales.height,
      ref: this.media,
      style: {transform},
      width: mediaScales.width,
      ...(isVideo ? VIDEO_PROPS : IMAGE_PROPS),
    });
  }

  private getMediaSrc(media: IMedia, zoom: ZoomFactor) {
    return media.mediaType === 'PHOTO'
      ? this.getMediaUrl(media, zoom)
      : `https://video.wixstatic.com/${media.videoFiles[0].url}`;
  }

  private renderHeader() {
    return (
      <header className={s.header} data-hook={Hooks.Header}>
        <a data-hook={Hooks.Close} onClick={e => this.handleClose(e)} className={s.close}>
          <CloseWithBackground />
        </a>
      </header>
    );
  }

  private setAxis({x, y}: ZoomAxis) {
    const currentMediaScales = this.currentMediaScales();
    const boundryX = Math.abs((this.state.width - currentMediaScales.width) / 2) - 2;
    const boundryY = Math.abs((this.state.height - currentMediaScales.height) / 2) + 2;
    const zoomOffset = {x: this.currentAxis.x - x, y: this.currentAxis.y - y};

    if (zoomOffset.x < -boundryX) {
      zoomOffset.x = -boundryX;
    }
    if (zoomOffset.x > boundryX) {
      zoomOffset.x = boundryX;
    }
    if (zoomOffset.y < -boundryY) {
      zoomOffset.y = -boundryY;
    }
    if (zoomOffset.y > boundryY) {
      zoomOffset.y = boundryY;
    }

    this.setState({zoomOffset});
  }

  private readonly handleMouseClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.setZoom(this.isZoomed ? Zoom.OFF : Zoom.ON, {
      x: this.isZoomed ? 0 : event.clientX,
      y: this.isZoomed ? 0 : event.clientY,
    });
  };

  private readonly handleMouseMove = (event: React.MouseEvent) => {
    if (this.isZoomed) {
      this.setAxis({x: event.clientX, y: event.clientY});
    }
  };

  private handleClose(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleClose();
  }

  private renderFooter() {
    const {media, currentIndex} = this.props;

    return (
      <footer>
        <ul data-hook={Hooks.DotNavigation} className={s.dots}>
          {media.map((m, i) => (
            <li key={`${m.url}-${i}`} className={cx({[s.navigation]: true, [s.selected]: currentIndex === i})}></li>
          ))}
        </ul>
      </footer>
    );
  }

  private readonly handleOnTouchStart = (event: React.TouchEvent) => {
    this.touchHypot = event.touches.length > 1 ? [this.calcHypot(event.touches)] : [0];
    this.currentAxis = {
      x: event.touches[0].clientX + this.state.zoomOffset.x,
      y: event.touches[0].clientY + this.state.zoomOffset.y,
    };
  };

  private calcHypot(touches: React.TouchList) {
    return Math.hypot(touches[0].clientX - touches[1].clientX, touches[0].clientY - touches[1].clientY);
  }

  private get pinchType(): PinchType {
    return this.touchHypot[this.touchHypot.length - 1] > this.touchHypot[0] ? PinchType.IN : PinchType.OUT;
  }

  private readonly handleOnTouchEnd = (event: React.TouchEvent) => {
    const momentumX = this.currentAxis.x - event.changedTouches[0].clientX;
    const momentumY = this.currentAxis.y - event.changedTouches[0].clientY;
    const isPinch = this.touchHypot[0];
    const isZoomed = this.isZoomed;
    const isVerticalSwipe = momentumY >= 150 || momentumY <= -150;
    const isSwipeLeft = momentumX >= 50;
    const isSwipeRight = momentumX <= -50;

    if (isPinch) {
      return this.setZoom(this.pinchType === PinchType.IN ? Zoom.ON : Zoom.OFF);
    }
    if (isZoomed) {
      return;
    }
    if (isVerticalSwipe) {
      return this.props.handleClose();
    }
    if (isSwipeLeft) {
      return this.navigateNext();
    }
    if (isSwipeRight) {
      return this.navigatePrev();
    }
  };

  private get isZoomed() {
    return this.state.zoom === Zoom.ON;
  }

  private currentMediaScales(): {width: number; height: number} {
    const {media, currentIndex} = this.props;
    return this.getMediaScales(media[currentIndex], this.zoomFactor);
  }

  private readonly handleOnTouchMove = (event: React.TouchEvent) => {
    if (this.touchHypot[0] && event.changedTouches.length > 1) {
      this.touchHypot = [...this.touchHypot, this.calcHypot(event.changedTouches)];
    }
    if (!this.isZoomed || this.touchHypot[0]) {
      return;
    }
    this.setAxis({x: event.changedTouches[0].clientX, y: event.changedTouches[0].clientY});
  };

  private readonly handleTap = (): void => {
    if (!this.isSingleTouch || this.currentMedia.mediaType !== 'PHOTO') {
      return;
    }
    if (this.taps) {
      this.resetTaps();
      this.setZoom(this.isZoomed ? Zoom.OFF : Zoom.ON);
      return;
    } else {
      this.taps = setTimeout(this.resetTaps, 300);
    }
  };

  private get isSingleTouch() {
    return this.touchHypot[0] === 0;
  }

  private readonly resetTaps = () => {
    clearTimeout(this.taps);
    this.taps = null;
  };

  private readonly setZoom = _.debounce((zoom: Zoom, nextAxis: ZoomAxis = {x: 0, y: 0}) => {
    this.currentAxis = nextAxis;
    this.setAxis(nextAxis);
    this.setState({zoom});
  }, 40);

  private navigatePrev() {
    const {currentIndex, media} = this.props;
    const to = currentIndex > 0 ? currentIndex - 1 : media.length - 1;
    this.handleNavigateTo(to);
  }

  private navigateNext() {
    const {currentIndex, media} = this.props;
    const to = currentIndex < media.length - 1 ? currentIndex + 1 : 0;
    this.handleNavigateTo(to);
  }

  private handleNavigateTo(n: number) {
    const {handleNavigateTo, currentIndex} = this.props;
    if (n === currentIndex) {
      return;
    }
    this.media.current.style.opacity = '0';
    timeout(() => handleNavigateTo(n));
  }

  private renderArrows() {
    if (this.props.isMobile) {
      return;
    }

    const {currentIndex, media} = this.props;
    const withPrev = currentIndex > 0;
    const withNext = currentIndex < media.length - 1;

    return (
      <>
        {withPrev && (
          <a data-hook={Hooks.ArrowPrev} className={cx(s.arrow, s.prev)} onClick={() => this.navigatePrev()}>
            <ArrowLeft />
          </a>
        )}
        {withNext && (
          <a data-hook={Hooks.ArrowNext} className={cx(s.arrow, s.next)} onClick={() => this.navigateNext()}>
            <ArrowRight />
          </a>
        )}
      </>
    );
  }

  private getMediaUrl(mediaItem: IMedia, zoomFactor: ZoomFactor) {
    const scale = this.getMediaScales(mediaItem, zoomFactor);
    const scaleWithDPR = {width: scale.width * this.dpr, height: scale.height * this.dpr};
    return getMediaUrl(mediaItem, scaleWithDPR, this.mediaOptions);
  }

  private warmUp(mediaItem: IMedia, zoomFactor: ZoomFactor) {
    const url = this.getMediaUrl(mediaItem, zoomFactor);
    this.warmed.push(url);
    new Image().src = url;
  }

  private get dpr(): number {
    return this.props.dpr;
  }

  private readonly handleKeydown = (event: KeyboardEvent) => {
    const isArrowRight = event.key === 'ArrowRight';
    const isArrowLeft = event.key === 'ArrowLeft';
    const isEscape = event.key === 'Escape';

    if (isArrowRight) {
      return this.navigateNext();
    }
    if (isArrowLeft) {
      return this.navigatePrev();
    }
    if (isEscape) {
      return this.props.handleClose();
    }
  };
}
